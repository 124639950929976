import React from 'react'
import { Link } from 'gatsby'


export default class Header extends React.Component {
  render() {
    const { headerLinks } = this.props;

    return (
        <h1
            style={{
                maxWidth: 700,
                textAlign: 'center',
                fontFamily: '"Hoefler Text",georgia,"times new roman",serif',
                fontSize: 34,
                textTransform: 'uppercase',
                color: '#666',
                fontWeight: 'normal',
                margin: '45px 0 0px',
        }}>
            <Link style={{cursor: 'pointer', color: '#443337', textDecoration: 'none' }} to={'/'}>
                Batist Leman
            </Link>
        </h1>
    )
  }
}
